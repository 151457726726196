<template>
  <div class="h-100" :key="componentKey">
    <div style = "padding-top:48px;" class="container-fluid bg-white mh-100 h-100 ">
      <div
        ref="conCont"
        v-bind:style="{paddingTop:'48px',  height: this.windowHeight + 'px' }"
        class="container-fluid mt--7 bg-white" >    
        <div class="row  pl-3 pr-3">
          <div class="col col-shadow no-padding">
            <div v-if="this.teamViewDisplay" class="teamViewMsgDiv"
              v-bind:style="{ height: this.windowHeight - 48 + 'px' }"              
            >
            <p>{{ $t('tvText') }}</p><br><br>
                <base-button class="dialogBtn" @click="openTvWeb"> {{$t('tvTextManual') }}</base-button>
            </div>
            
            <div v-else
              v-bind:style="{ height: this.windowHeight - 48 + 'px' }"
              ref="medviewDiv"
              id="medview_container"
            ></div>

            
          </div>
        </div>    
      </div>
    </div> 
  <v-dialog />
  </div>  
</template>
<script>
/* global dh */
//import json from '../../public/medview/test.json'
import StudyStore from "../store/studyStore";
import authHeader from "../services/authHeader";
import axios from "axios";
import {eventBus} from "../main.js";
import i18n from "../i18n.js";
import { checkIfValidLicence } from '../helper/tvWebLicenseChecker.js';
import TvWebDataLoader from "../helper/tvWebDataLoader.js";

  const MedViewProxy = {
    GetConfig: function() {
        var config = {
            "annotation": {
                "top_left":
                        [
                            [ "patient", "displayName"],
                            [ "patient", "birthdate", "* ", "date"],
                            [ "patient", "accountId", "ID: " ]
                        ],
                "top_right":
                        [
                            [ "study", "description", "" ],
                            [ "study", "date_time", "", "date" ],
                            [ "study", "date_time", "" , "time"],
                            [ "study", "refphysician"]
                        ],
                "bottom_left":
                        [
                            [ "series", "number", "Series: " ],
                            [ "series", "modality"],
                            [ "study", "bodyPart"],
                            [ "series", "description"]
                        ]
            }
        };
        return config;
    },

  /**
   * Function to read an image by UUID.
   *
   * @param {string} token IN session token
   * @param {object} image IN image object
   * @param {object} imgData IN/OUT {array: [out-Int8Array], onload: function(evt), onerror: function(evt) }
   * @returns {void}
   */
  GetObject: function (token, image, imgData) {
    var uuid = image.uuid;
    // Load image with Ajax and create a Int8Array Image
    var req = new dh.crypto.HttpRequest();
    req.setAesKey(StudyStore.data.currentStudyKeys[uuid]);
    var url = axios.defaults.baseURL + "/v1/images/" + uuid;
    req.open("GET", url, true);
    req.setRequestHeader("Authorization", authHeader().Authorization + "");
    req.setRequestHeader(
      "Accept",
      "application/octet-stream, application/json, text/plain, */*"
    );
    req.responseType = "arraybuffer";
    req.onload = function (event) {
      var data = req.response;
      if (data && req.status < 400) {
        // Successfully loaded, verify that it is an image
        var type = dh.util.fileType(data);
        if (!type || !type.image) {
          console.error("Decrypted data is not an image: " + url);
          if (imgData.onerror) {
            imgData.onerror(event);
          }
        } else {
          imgData.array = new Int8Array(data);
          if (imgData.onload) {
            imgData.onload(event);
          }
        }
      } else {
        //if !sessionTimeout then other error
        // if (!me.checkSessionTimeoutFunc("null", req)) {
        //     // Data not received or error
        //     console.log("Image load HTTP status: " + req.status + ": " + req.statusText);
        //     imgData.array = null;
        //     if (imgData.onerror) {
        //         imgData.onerror(event);
        //     }
        // }
      }
    };
    req.onerror = imgData.onerror;
    req.onabort = imgData.onerror;
    req.send();
  },

  /**
   * Function to read image meta-data by UUID.
   *
   * @param {string} token IN session token
   * @param {string} uuid IN image UUID
   * @param {integer} segmentNumber IN Image segment number
   * @param {object} metaData IN/OUT {meta: [out-JSON object], onload: function(evt), onerror: function(evt) }
   * @returns {void}
   */
  GetObjectMeta: function (token, uuid, segmentNumber, metaData) {
    console.log("Retrieve meta: " + token + ", " + uuid);

    // Load image meta-data with Ajax synchronously
    var req = new XMLHttpRequest();
    req.setRequestHeader("Authorization", authHeader().Authorization);
    var url = "medview/demo-img/" + uuid + ".json";
    req.open("GET", url, true);
    // req.responseType = "json"; // Not working in all browsers
    req.responseType = "text";
    req.onload = function (oEvent) {
      console.log("Loaded meta. Status: " + req.status + ": " + url);
      // Manipulate the data here if necessary (decrypt)
      if (req.response) {
        // Successfully loaded
        var jsonResponse = JSON.parse(req.response);
        metaData.meta = jsonResponse.meta;
        if (metaData.onload) {
          metaData.onload(oEvent);
        }
      } else {
        // Data not received
        metaData.meta = null;
        if (metaData.onerror) {
          metaData.onerror(oEvent);
        }
      }
    };
    req.onerror = metaData.onerror;
    req.onabort = metaData.onerror;
    req.send();
  },
};

const topPadding = 48

export default {
 
  data() {
    return {
      teamViewDisplay : false,
      windowHeight: window.innerHeight - topPadding,
      componentKey: 0,
      resizeTimer:null,
      triggeredByResize: false,
      triggeredByMounted: false,
      seriesString: "",
      defaultLoad: true,
      tvWebTriggered: false,
    };
  },
  mounted() {
    this.loader = new TvWebDataLoader();
    this.tvWebTriggered = false;

    eventBus.$on('linkClicked', () => {
      this.componentKey += 1;      
    })

    let script = document.createElement('script')
    script.setAttribute('src', "rangeslider/js/rangeslider.min.js");
    document.head.appendChild(script)  

    eventBus.$on('changeMedviewLang', () => {      
      this.$refs.medviewDiv.innerHTML = null;
      this.initiateMedview();
    });

    this.$nextTick(() => {

      var me = this; 

       window.addEventListener("resize", function() {
          clearTimeout(me.resizeTimer); 
          me.resizeTimer = setTimeout(me.onResize,500); 
        });
    });
    this.initiateMedview();
    this.triggeredByMounted = true;
    this.adjustDisplay();
  },

  updated(){

    if (!this.triggeredByResize && !this.triggeredByMounted) {      
        this.initiateMedview();        
    }

    this.triggeredByResize = false;
    this.triggeredByMounted = false;
  },

  methods: {
    async openTvWeb() {
      if ((await checkIfValidLicence()) == true) {
        this.loader.prepareTvWebPayload();
      } else {
        //do err
        localStorage.setItem("teamViewDisplay", false);
        this.$fire({
          title: "Invalid licence",
          text: "Your teamView web licence is not valid",
          type: "error",
        });
        return;
      }
      
    },
    adjustDisplay() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      var mql = window.matchMedia("screen and (max-width: 765px)")

      this.windowHeight = window.innerHeight + 96 - topPadding;
      
      if (isMobile || mql.matches) {
        this.windowHeight = window.innerHeight -  topPadding;
     }
    },

    async initiateMedview() {        
      if (window.localStorage.getItem("teamViewDisplay")){
        if (await checkIfValidLicence() == true ) {
          this.teamViewDisplay = true;
          this.windowHeight = window.innerHeight + 96 - topPadding;

          //just trigger study load for sidebar
          //tvweb is loaded elsewhere (dashboard)
          await StudyStore.methods.loadActiveStudy(false);
          return;
        }                  
      }else {
        this.teamViewDisplay = false;
      }

      var mvc = this.$refs.medviewDiv;

      //HC-108
      this.seriesString = i18n.t("series");
      var me = this;

      MedViewProxy.GetConfig = function() {
           var config = {
            "annotation": {
                "top_left":
                        [
                            [ "patient", "displayName"],
                            [ "patient", "birthdate", "* ", "date"],
                            [ "patient", "accountId", "ID: " ]
                        ],
                "top_right":
                        [
                            [ "study", "description", "" ],
                            [ "study", "date_time", "", "date" ],
                            [ "study", "date_time", "" , "time"],
                            [ "study", "refphysician"]
                        ],
                "bottom_left":
                        [
                            [ "series", "number", me.seriesString + ":"  ],
                            [ "series", "modality"],
                            [ "study", "bodyPart"],
                            [ "series", "description"]
                        ]
            }
        };
        return config;
      };
      

      if (typeof dh === 'undefined' || typeof dh.medview === 'undefined'){
        setTimeout(() => {
          this.initiateMedview();
        }, 1000);
        return;
      }

      let viewer = new dh.medview.Medview({
        container: mvc,
        sessionToken: "very-long--authentication-token-38udhsgetwsjdshf",
        proxy: MedViewProxy,
      });

      await StudyStore.methods.loadActiveStudy(viewer);
    },
    onResize() {
      this.triggeredByResize = true;
      this.adjustDisplay();
    },
  },
};
</script>
<style>

@import '../../public/rangeslider/css/rangeslider.min.css';
.teamViewMsgDiv {
  padding:50px;
}

.container-fluid {
  padding-left:0px !important;
  padding-right:0px !important;
}

#medview_container *,
#medview_container *:before,
#medview_container *:after {
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  line-height: 1;

}
.toolInput {
  width: 95px !important;
  float: right !important;
}
</style>
