<template>
  <div>
    <base-header type="dashboard" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--9 mb-3 bg-white">
      <div class="row pr-4 pl-4">
        <div class="col col-shadow no-padding">
          <div class="col col-shadow no-padding">
            <div>
              <loading :active="this.activeLoading" :isFullPage=true></loading>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import accountStore from '../store/accountStore';
import studyStore from '../store/studyStore'
import reportStore from '../store/reportStore'
import Vue from 'vue'

export default {
  components: {
    Loading
  },
  data() {
    return {
      activeLoading: true
    };
  },
  mounted() {
    let self = this;
    accountStore.methods.fetch(null, function (entries) {
      if (entries) {

        var firstEntry = entries[0];

        if (firstEntry.type == "report") {
          reportStore.data.activeReport = firstEntry;
        } else {
          studyStore.data.activeStudy = firstEntry;
          localStorage.setItem("activeStudy", JSON.stringify(firstEntry));
        }

        for (const entry of entries) {
          if (entry.type === "study") {
            firstEntry = entry;
            studyStore.data.activeStudy = entry;
            localStorage.setItem("activeStudy", JSON.stringify(firstEntry));
            break; 
          }
        }

        self.$router.push('/' + firstEntry.type + "/" + firstEntry.id);
        Vue.prototype.$activeDocument = firstEntry.type;
      } else {
        self.$router.push('/home');
      }
    });
  }
}
</script> 


