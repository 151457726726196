<template>
  <div>
    <div :key="componentKey" class="container-fluid bg-white mh-100 h-100">
      <div class="row pr-3 pl-3">
        <div class="col col-shadow no-padding">
          <div
            v-bind:style="{ height: this.windowHeight + 'px' }"
            class="col  no-padding"
          >
            <div class="pdfHeader card-header">
              <button
                class="pdfControlBtn btn float-right btn-default btn-sm fas fa-search-minus"
                @click="scale -= scale > 0.2 ? 0.1 : 0"
              ></button>    
              <button
                class="pdfControlBtn btn float-right btn-default btn-sm fas fa-search-plus"
                @click="scale += scale < 2 ? 0.1 : 0"
              ></button>
              <button
                class="pdfControlBtn btn float-right btn-default btn-sm fas fa-backward"
                @click="page > 1 ? page-- : 1"
              ></button>
              <button
                class="pdfControlBtn btn float-right btn-default btn-sm fas fa-forward"
                @click="page < numPages ? page++ : 1"
              ></button>
              <button
                class="pdfControlBtn btn float-right btn-default btn-sm fas fa-compress"
                @click="scale='page-width'"
              ></button>
              <button
                class="pdfControlBtn btn float-right btn-default btn-sm fa fa-print"
                @click="printPdf"
              ></button>
              <i class ="ni education_hat mr-2"> {{$t('page')}}: {{this.page}}/{{this.numPages}} </i>
            </div>
            <div class="pdfContent">
              <pdf ref="pdfDoc" v-if="pdfdata != null" :text="false"  :src="pdfdata" :scale.sync="scale" :page="page"></pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global dh */
import pdfvuer from "pdfvuer";
import authHeader from '../services/authHeader';
import reportStore from '../store/reportStore';
import axios from 'axios';
import Vue from 'vue'
import {eventBus} from "../main.js";
import printJS from 'print-js'


const topPadding = 48;

export default {
  components: {
    pdf: pdfvuer,
  },

  data() {
    return {
      windowHeight: window.innerHeight - topPadding,
      page: 1,
      numPages: 0,
      pdfdata: null,
      scale: "page-width",
      componentKey: 0,
      pdfBlob: null
    };
  },
  computed: {
    formattedZoom() {
      return Number.parseInt(this.scale * 100);
    },
  },
  mounted() {
    
    eventBus.$on('linkClickedReport', () => {      
      this.loadReport();    
    })

    this.resizePdf();
    this.$nextTick(function () {
      window.addEventListener("resize", this.resizePdf);
    });

    this.loadReport();

  },

  methods: {

    printPdf() {
      printJS(this.pdfBlob);
    },

    loadReport() {
      let self = this;
      this.page = 1;
      var callback = function() {
        self.getAndShowReport();
      }
      reportStore.methods.loadReport(callback)
    },

    resizePdf() {
      this.scale = "page-width";
      this.windowHeight = window.innerHeight - topPadding;

      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      var mql = window.matchMedia("screen and (max-width: 765px)")
            this.windowHeight = window.innerHeight ;
      
      if (isMobile || mql.matches) {
        this.windowHeight = window.innerHeight - topPadding - 48;
      }

    },

    getAndShowReport() {
      var self = this;
      var req = new dh.crypto.HttpRequest();
      req.setAesKey(reportStore.data.currentReportKey);

      console.log(reportStore.data.currentReportKey);

      var url = axios.defaults.baseURL + "/v1/reports/" + reportStore.data.activeReport.id;
      req.open("GET", url, true);
      req.setRequestHeader("Authorization", authHeader().Authorization+"")
      req.setRequestHeader("Accept", "application/octet-stream, application/json, text/plain, */*");
      req.responseType = "arraybuffer";
      req.onload = function() {
          var data = req.response;
          if (data && req.status < 400) {
              // Successfully loaded, verify that it is an report
              var type = dh.util.fileType(data);
              if (!type) {
                  console.error("Decrypted data is not a report: " + url);
              }
              else {
                  var blob = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));              
                  Vue.prototype.$activeReport = blob;
                  Vue.prototype.$activeDocument = "report";
                  self.pdfBlob = blob;
                  self.pdfdata = pdfvuer.createLoadingTask(blob);
                  
                  self.pdfdata.then((pdf) => {
                    self.numPages = pdf.numPages;
                  });

                  eventBus.$emit('documentLoaded', reportStore.data.activeReport.id );
                  self.componentKey += 1;  

              }
          }
          else {
            console.log("request failed");
          }
      };
      req.send();
    },
  },
};
</script>

<style lang="css" scoped>


.container-fluid {
  padding-left:0px !important;
  padding-right:0px !important;
  
}
.education_hat {
  font-family: 'Open Sans', sans-serif !important;
}

.page {
  background-clip: initial !important;
  border-image: initial !important;
  margin: 5px !important;
}
.pdfContent {
  overflow: scroll;
  padding-right: 5px;
  padding-left: 5px;
  
  height: calc(100% - 50px);
  background: #f8f9fe;
}
.pdfHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 50px;
  z-index: 1;
  background: #f8f9fe;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
